import { createContext, useReducer, useEffect, useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, BtnDot, Type } from '@saladbob/sassafras'
import { X } from 'react-feather';

export const ModalContext = createContext(null);
export const ModalDispatchContext = createContext(null);

let modal;

type Modal = {
    content: React.ReactNode,
    title?: string,
    open?: boolean,
    onClose?: () => void,
    onConfirm?: () => void,
    width?: number | string,
    height?: number | string,
};

type ModalAction = {
    content: React.ReactNode,
    title?: string,
    open?: boolean,
    onClose?: () => void,
    onConfirm?: () => void,
    width?: number | string,
    height?: number | string,
};

type ModalReducer = (state: Modal, action: ModalAction) => Modal;

const Modal: React.FC<{open: boolean}> = ({ open: op }) => {
    const { title, content, onClose, width, padding = 'md', height } = useModal();
    modal = useModalDispatch();

    modal.open = (args) => {
        modal({
            ...args,
            open: true,
        });
    };

    modal.close = () => {
        modal({
            open: false,
        });
    }

    const [open, setOpen] = useState(false);

    const onComplete = () => {
        if (open && typeof onClose === 'function') {
            onClose();
        }
    }

    const onClick = () => {
        modal.close();
    }

    useEffect(() => {
        if (op !== open) {
            setOpen(op);
        }
    }, [op]);

    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    onAnimationComplete={onComplete}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.25 }}
                    onClick={onClick}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1000,
                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <motion.div
                        onAnimationComplete={onComplete}
                        initial={{ y: -40 }}
                        animate={{ y: 0 }}
                        exit={{ y: -40 }}
                        transition={{ duration: 0.25 }}
                        style={{
                            width: '100%',
                            maxWidth: width || 640,
                            maxHeight: height,
                            padding: 32,
                        }}
                    >
                        <Box
                            width="100%"
                            height="100%"
                            bgColor="white"
                            onClick={(e) => e.stopPropagation() }
                            depth="md"
                            rounded="sm"
                        >   
                            {title && (
                                <Box bgColor="tertiary" padding={['sm', 'md']} rounded={['sm', 'sm', 0, 0]}>
                                    <Type font="h4" tag="p" bgColor="tertary" bold>{title}</Type>
                                </Box>
                            )}
                            <Box padding={padding}>
                                {content}
                            </Box>
                            <div style={{ position: 'absolute', top: -16, right: -16}}>
                                <BtnDot color="black" onClick={onClick}><X /></BtnDot>
                            </div>
                        </Box>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    )
};

export function ModalProvider(): React.ReactElement {
    const [open, setOpen] = useState(false);
    const [modal, dispatch] = useReducer<ModalReducer>(
        modalReducer,
        {
            content: null,
            title: null,
            onClose: null,
            onConfirm: null,
        },
    );

    useEffect(() => {
        if (modal.open) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [modal.open]);

    return (
        <ModalContext.Provider value={modal}>
            <ModalDispatchContext.Provider value={dispatch}>
                <Modal open={open} />
            </ModalDispatchContext.Provider>
        </ModalContext.Provider>
    );
}

export function useModal() {
    return useContext(ModalContext);
}
  
export function useModalDispatch() {
    return useContext(ModalDispatchContext);
}

function modalReducer(state: Modal, action: ModalAction): Modal {
    if (action.open === false) {
        return {
            ...state,
            open: false,
        }
    }
    return action;
}

export { modal };